<template>
	<div>
		<div class="box" @click.self="shopShow = false" v-loading.fullscreen.lock="fullscreenLoading">
			<ExportExcel :json_data="json_data" :json_fields="json_fields" name="自媒体.xls" />
			<div class="ct_box">
				<div class="tab_ul flex" style="justify-content: space-between">
					<UserMediaNav title="2" />

				</div>
				<!-- 门户类型 -->
				<div class="type2 flex">
					<p>所属平台：</p>
					<ul class="flex ul2">
						<li :class="PortalType.title == '不限' ? 'active' : ''" @click="PortalShow('不限', 0)">
							不限
						</li>
						<li :class="PortalType.title == item ? 'active' : ''" v-for="(item, index) in PortalList"
							:key="index" @click="PortalShow(item, index)">
							<!-- PortalType = item -->
							{{ item }}
						</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>行业类型：</p>
					<ul class="flex ul2">
						<li :class="ChannelType.title == '不限' ? 'active' : ''" @click="ChannelShow('不限', 0)">
							不限
						</li>
						<li :class="ChannelType.title == item ? 'active' : ''" v-for="(item, index) in ChannelList"
							:key="index" @click="ChannelShow(item, index)">
							{{ item }}
						</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>参考粉丝数：</p>
					<ul class="flex ul2">
						<li :class="TerritoryType.title == '不限' ? 'active' : ''" @click="TerritoryShow('不限', 0)">
							不限
						</li>
						<li :class="TerritoryType.title == item ? 'active' : ''" v-for="(item, index) in TerritoryList"
							:key="index" @click="TerritoryShow(item, index)">
							{{ item }}
						</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>平均阅读量：</p>
					<ul class="flex ul2">
						<li :class="RecordingType.title == '不限' ? 'active' : ''" @click="RecordingShow('不限', 0)">
							不限
						</li>
						<li :class="RecordingType.title == item ? 'active' : ''" v-for="(item, index) in RecordingList"
							:key="index" @click="RecordingShow(item, index)">
							{{ item }}
						</li>
					</ul>
				</div>

				<div class="type2 flex">
					<p>收录效果：</p>
					<ul class="flex ul2">
						<li :class="includeType.title == '不限' ? 'active' : ''" @click="includeShow('不限', 0)">不限</li>
						<li :class="includeType.title == item ? 'active' : ''" v-for="(item, index) in includeList"
							:key="index" @click="includeShow(item, index)">
							{{ item }}
						</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>是否官方：</p>
					<ul class="flex ul2">
						<li @click="isOfficialShow('不限')" :class="officialType == '不限' ? 'active' : ''">
							不限
						</li>
						<li v-for="(item, index) in officialList" :key="index"
							:class="officialType == item ? 'active' : ''" @click="isOfficialShow(item)">{{ item }}</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>V认证：</p>
					<ul class="flex ul2">
						<li @click="isVAuthShow('不限')" :class="isVAuthType == '不限' ? 'active' : ''">
							不限
						</li>
						<li v-for="(item, index) in isVAuthList" :key="index"
							:class="isVAuthType == item ? 'active' : ''" @click="isVAuthShow(item)">{{ item }}</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>价格区间：</p>
					<ul class="flex ul2">
						<li :class="MoneyType.title == '不限' ? 'active' : ''" @click="MoneyShow('不限', 0)">
							不限
						</li>
						<li :class="MoneyType.title == item ? 'active' : ''" v-for="(item, index) in MoneyList"
							:key="index" @click="MoneyShow(item, index)">
							{{ item }}
						</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>特殊类目：</p>
					<ul class="flex ul2">
						<li :class="SpecialType == '不限' ? 'active' : ''" @click="SpecialShow('不限')">不限</li>
						<li v-show="SpecialList.is_pub_contact" :class="SpecialType == '可发联系方式' ? 'active' : ''"
							@click="SpecialShow('可发联系方式')">
							{{ SpecialList.is_pub_contact }}
						</li>

						<li v-show="SpecialList.is_pub_image" :class="SpecialType == '焦点图' ? 'active' : ''"
							@click="SpecialShow('焦点图')">
							{{ SpecialList.is_pub_image }}
						</li>
						<li v-show="SpecialList.is_pub_url" :class="SpecialType == '文字链' ? 'active' : ''"
							@click="SpecialShow('文字链')">
							{{ SpecialList.is_pub_url }}
						</li>
						<li v-show="SpecialList.is_pub_weekend" :class="SpecialType == '周末可发' ? 'active' : ''"
							@click="SpecialShow('周末可发')">
							{{ SpecialList.is_pub_weekend }}
						</li>
						<!-- 新加特殊类目项:是否可加配图 是否可加视频 -->
						<li v-show="SpecialList.is_pub_video" :class="SpecialType == '是否可加视频' ? 'active' : ''"
							@click="SpecialShow('是否可加视频')">
							{{ SpecialList.is_pub_video }}
						</li>
					</ul>
				</div>

				<div class="type3 flex">
					<p>排序：</p>
					<ul class="flex ul2">
						<!-- 状态码：0:未选中 1:升序 2:降序-->
						<li @click="StateShow('', '默认')" :class="SortType.title == '默认' ? 'active' : ''">
							默认
						</li>

						<li v-if="SortList.member_price" @click="StateShow('member_price', '价格')" :id="SortType.title != '默认' && SortType.title == '价格' && MoneyState == 1
			? 'activeTrue'
			: SortType.title != '默认' && SortType.title == '价格' && MoneyState == 2
				? 'activeFalse'
				: ''
			" :class="SortType.title == '价格' ? 'active' : ''">
							价格
						</li>

						<li v-if="SortList.weight" @click="StateShow('weight', '电脑权重')" :id="SortType.title != '默认' &&
			SortType.title == '电脑权重' &&
			CopmutedState == 1
			? 'activeTrue'
			: SortType.title != '默认' &&
				SortType.title == '电脑权重' &&
				CopmutedState == 2
				? 'activeFalse'
				: ''
			" :class="SortType.title == '电脑权重' ? 'active' : ''">
							权重
						</li>


					</ul>
				</div>
				<div class="type4 flex">
					<p class="type_title_n"> 媒介名称：</p>
					<el-input @keyup.enter.native="page = 1; ListFn()" v-model="mediaName"
						placeholder="请输入编号或名称"></el-input>
					<div class="flex link_box">
						<p>可带链接：</p>
						<el-select v-model="link" placeholder="请选择">
							<el-option v-for="(item, index) in linkList" :key="index" :label="item" :value="item">
							</el-option>
						</el-select>
					</div>
					<div class="flex link_box">
						<p>可发联系方式：</p>
						<el-select v-model="relation" placeholder="请选择">
							<el-option v-for="(item, index) in linkList" :key="index" :label="item" :value="item">
							</el-option>
						</el-select>
					</div>
					<button class="sf_but" type="primary" @click="page = 1; ListFn()">筛选</button>
					<button class="sf_but2" type="primary" @click="page = 1; AllMediaTab()">
						重置筛选
					</button>
					<mediaWhiteList />

				</div>
			</div>
			<div class="table_box">
				<Title title="资源列表" />

				<div class="table_bg_box">
					<!-- 自媒体 -->
					<div class="flex" style="justify-content: space-between;padding: 0 2rem;box-sizing: border-box;">
						<div></div>
						<ul class="SetMenu_tab flex">
							<li @click="SetMenuTab(0)" :class="collect == 0 ? 'SetMenu_Show' : ''">
								全部媒介
							</li>
							<li @click="SetMenuTab(1)" :class="collect == 1 ? 'SetMenu_Show' : ''">
								我的收藏
							</li>
						</ul>
					</div>
					<table class="table_2">
						<thead>
							<td>
								<input :disabled="tableData.length == 0" type="checkbox" v-model="Allcheck"
									@click="checkSwitch()" label="" v-show="tableData.length != 0" />
							</td>
							<td> 媒介名称</td>
							<td>平台</td>
							<td>价格</td>
							<td>行业类型</td>
							<td>可发内链</td>
							<td>可发联系方式</td>
							<td>粉丝数</td>
							<td>平均浏览量</td>
							<td>V认证</td>
							<!-- <td>截稿时间</td>
							<td>出稿速度</td> -->
							<td>备注(仅供参考)</td>
							<td>收藏</td>
						</thead>
						<tbody>
							<tr v-for="(item, index) in tableData" :key="index">
								<td>
									<input type="checkbox" v-model="item.check" @click="onPitch(index)" label="" />
								</td>
								<td>
									<td_remark :text="item.title" />
								</td>
								<td>{{ item.portal_title }}</td>
								<td>
									￥{{ item.price.toFixed(2) }}
								</td>
								<td>{{ item.industry_classify_title }}</td>
								<td :style="{
			color: item.is_pub_url == 1 ? '#55C964' : '#FF0000',
		}">
									{{ item.is_pub_url == 0 ? '否' : '是' }}
								</td>
								<td :style="{
			color: item.is_pub_contact == 1 ? '#55C964' : '#FF0000',
		}">
									{{ item.is_pub_contact == 0 ? '否' : '是' }}
								</td>

								<td>
									{{ item.fans_num }}
								</td>
								<td>
									{{ item.views }}
								</td>
								<td :style="{
			color: item.is_v_auth == 1 ? '#55C964' : '#FF0000',
		}">
									{{ item.is_v_auth == 0 ? '否' : '是' }}
								</td>
								<!-- <td>{{ item.stop_draft_time }}:00</td>
								<td>
									{{
			item.draft_speed == 0
				? '不限'
				: item.draft_speed == 1
					? '1小时'
					: item.draft_speed == 2
						? '2小时'
						: item.draft_speed == 3
							? '3小时'
							: item.draft_speed == 4
								? '4小时'
								: item.draft_speed == 5
									? '当天'
									: '次日'
		}}
								</td> -->
								<td>
									<td_remark :text="item.remark" />
								</td>
								<td>
									<div class="collect_box flex">
										<p @click.stop="jumpLink(item)">查看案例</p>
										<!-- v-if="item.collect == 0"  -->
										<p v-if="item.is_collect == 0" class="collect" @click.stop="collectGet(item)">收藏
										</p>
										<!-- v-if="item.collect == 1" -->
										<p v-if="item.is_collect == 1" class="collect2"
											@click.stop="collectClose(item)">已收藏
										</p>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
					<!-- {{ $store.state.shopObj }} -->
					<!-- 分页器 -->
					<pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page" />
				</div>
			</div>
		</div>
		<!-- 购物车 -->
		<div v-show="shopShow == false" class="shopping_car">
			<div class="car" @click.stop="shopShow = true">
				<p>{{ CarList.length }}</p>
			</div>
		</div>
		<div :class="shopShow ? 'shop_box' : 'shop_none_box'">
			<div class="title flex" style="justify-content: space-between; align-items: center">
				<h3 class="carTitle">已选媒体: {{ CarList.length }}</h3>
				<button class="empty_but" v-show="CarList.length != 0" @click="empty()">
					清空已选媒体
				</button>
				<span class="close" @click="shopShow = false"></span>
			</div>
			<ul class="listbox" v-show="CarList.length != 0">
				<li v-for="(item, index) in CarList" :key="index">
					<!-- <img :src="item.logo" alt="" /> -->
					<div>
						<td_remark :text="item.title" />
					</div>
					<p class="price">￥{{ item.price.toFixed(2) }}</p>
					<span class="deleteIcon" @click.stop="deleteFn(item)"></span>
				</li>
			</ul>
			<el-empty v-show="CarList.length == 0" description="您还没有选择资源赶紧去看看心仪的资源吧"></el-empty>
			<div class="bottom_box">
				<div class="flex" style="justify-content: space-between; margin-bottom: 1rem">
					<p style="font-size: 1.4rem; color: #999">合计：</p>
					<p class="totalMoney">
						￥<span>{{ totalMoney }}</span>
					</p>
				</div>
				<button class="put_but" @click="putFn(item)">立即投放</button>
			</div>
		</div>
	</div>
</template>

<script>
import UserMediaNav from '../../components/UserMediaNav.vue'
import { mapState } from 'vuex'
import { checkAll } from '@/util/checkAll'
import ExportExcel from '@/components/exportExcel.vue';
export default {
	name: '',
	created() { },
	mounted() {
		this.fullscreenLoading = true
		setTimeout(() => {
			for (const i in this.$store.state.UserMedia) {
				if (this.$store.state.UserMedia[i].id == 2) {
					this.PortalList = this.$store.state.UserMedia[i].portal_list // 所属平台
					// console.log(this.PortalList);
					this.ChannelList = this.$store.state.UserMedia[i].industry_list
					this.TerritoryList = this.$store.state.UserMedia[i].fans_list
					this.isVAuthList = this.$store.state.UserMedia[i].is_v_auth_list

					this.includeList = this.$store.state.UserMedia[i].inlet_category
					this.officialList = this.$store.state.UserMedia[i].official_list
					this.RecordingList = this.$store.state.UserMedia[i].views_list
					this.MoneyList = this.$store.state.UserMedia[i].price_range
					this.SpecialList = this.$store.state.UserMedia[i].special_list
					this.SortList = this.$store.state.UserMedia[i].order_list
				}
			}

			this.ListFn()
			this.fullscreenLoading = false
		}, 300)
	},
	computed: {
		...mapState(['UserMedia', 'MediaNav', 'UserTypeItem', 'fillActive']),
		totalMoney: function () {
			let n = 0;
			this.CarList.forEach((item, index) => {
				if (this.CarList[index].check) {
					n += this.CarList[index].price;
				}
			})
			return n.toFixed(2);
		}
	},
	watch: {
		tableData: {
			handler(newValue) {
				if (this.tableData.length > 0) {
					let count = 0
					for (var i = 0; i < this.tableData.length; i++) {
						if (this.tableData[i].check == true) {
							count++;
						}
					}
					if (count == this.tableData.length) {
						this.Allcheck = true
					} else {
						this.Allcheck = false
					}
				}
			},
			deep: true,
		},
		CarList:{
            handler(){
                this.excelPush()
            },
            deep:true,
        }

	},
	components: {
		UserMediaNav,
		ExportExcel
	},

	data() {
		return {
			// 门户类型
			PortalType: {
				id: 0,
				title: '不限',
			},
			PortalList: [],
			// 频道类型
			ChannelType: {
				id: 0,
				title: '不限',
			},
			ChannelList: [],
			//所属区域
			TerritoryType: {
				id: 0,
				title: '不限',
			},
			TerritoryList: [],
			// 收录效果
			includeType: {
				id: 0,
				title: '不限'
			},
			// 是否官方筛选项
			officialType: '不限',
			officialList: [],
			includeList: [],
			// 是否v认证
			isVAuthType: '不限',
			isVAuthList: [],
			RecordingType: {
				id: 0,
				title: '不限',
			},
			RecordingList: [],
			// 价格区间
			MoneyType: {
				id: 0,
				title: '不限',
			},
			MoneyList: [],
			collect: 0,
			// 特殊类目
			SpecialType: '不限',
			SpecialList: [],
			// 排序
			SortType: {
				id: 0,
				title: '默认',
			},
			SortList: [],
			MoneyState: 0, //价钱升序降序控制器
			CopmutedState: 0, //电脑权重升序降序控制器
			MoveState: 0, //移动权重升序降序控制器
			/*-----------------媒体筛选------------------------*/
			mediaName: '', // 媒介名称,
			linkList: ['是', '否'], //可发链接列表
			link: '', //可发链接
			relation: '', //可发联系方式
			/*数据表格*/
			tableData: [],
			CarList: [],
			// 购物车任务栏切换
			shopShow: false,
			Allcheck: false,
			fullscreenLoading: false,
			total_page: 0, //分页总页数
			count: 0, //总条数
			page: 1,
			title: '自媒体',
			json_fields: {
				" 媒介名称": 'title',
				"平台": 'portal_title',
				"价格": 'price',
				"行业类型": 'industry_classify_title',
				"可发内链": 'is_pub_url',
				"可发联系方式": "is_pub_contact",
				"案例链接": 'case_url',
				"出稿速度": 'draft_speed',
				"粉丝数": 'fans_num',
				"平均浏览量": 'views',
				"是否官方": 'is_official',
				"权重": 'weight',
				"截稿时间": 'stop_draft_time',
				"备注": 'remark',
			},
			json_data: []
		}
	},
	methods: {
		pageChange(pageVal) {
			this.page = pageVal
			this.ListFn()
		},
		// 切换收藏列表
		SetMenuTab(num) {
			this.collect = num
			this.ListFn()
		},
		StateShow(type, item) {
			/*
			每次点击时需要重置价格-电脑权重-移动权重的状态,在每个判断条件中需做重置
			*/
			this.SortType = {
				title: item,
				type: type
			}
			// console.log(this.SortType)
			switch (item) {
				case '默认':
					this.MoneyState = 0
					this.CopmutedState = 0
					this.MoveState = 0
					break

				case '价格':
					this.CopmutedState = 0
					this.MoveState = 0
					if (this.MoneyState == 0) {
						this.MoneyState = 1
					} else if (this.MoneyState == 1) {
						this.MoneyState = 2
					} else {
						this.MoneyState = 1
					}

				default:
					break
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		onPitch(index) {
			// 飞入购物车动画
			this.tableData[index].check = !this.tableData[index].check
			if (this.tableData[index].check) {
				const x = event.clientX - 20
				const y = event.clientY - 20
				this.createBall(x, y)
				this.shopShow = true
				// this.CarList.push(this.tableData[index])
				this.CarList.push(this.tableData[index])
				/*------------------------------------------------------ */
			} else {
				this.tableData.forEach((item, index) => {
					this.CarList.forEach((el, i) => {
						if (!this.tableData[index].check && this.CarList[i].id == this.tableData[index].id) {
							this.CarList.splice(i, 1)

						}
					})
				})
			}
		},
		// 清空购物车
		empty() {
			this.$confirm('是否清空已选媒体?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.fullscreenLoading = true
					// setTimeout(() => {
					this.CarList = []
					this.shopShow = false
					// this.toggleSelection()
					this.tableData.forEach((item, index) => {
						if (item.check) {
							console.log(item)
							return (item.check = false)
						}
					})
					this.fullscreenLoading = false
					// }, 500)
					this.$message({
						type: 'success',
						message: '已清空已选媒体',
					})
				})
				.catch(() => {
					this.$message({
						type: 'warning',
						message: '已删除',
					})
				})
		},

		createBall(left, top) {
			const bar = document.createElement('ball')
			bar.style.position = 'fixed'
			bar.style.left = left + 'px'
			bar.style.top = top + 'px'
			bar.style.width = '20px'
			bar.style.height = '20px'
			bar.style.borderRadius = '50%'
			bar.style.backgroundColor = 'red'
			bar.style.transition =
				'left 1s linear, top 1s cubic-bezier(0.5, -0.5, 1, 1)'
			document.body.appendChild(bar)
			setTimeout(() => {
				const x = document.body.clientWidth * 1.5
				const y = document.body.clientHeight * 1 - 500
				bar.style.top = y + 'px'
				console.log(y)
				bar.style.left = x + 'px'
			}, 0)
			bar.ontransitionend = function () {
				this.remove()
			}
		},
		// V认证
		isVAuthShow(item) {
			this.isVAuthType = item
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 全选 反选
		checkSwitch() {
            this.Allcheck = !this.Allcheck
            this.CarList = checkAll(this.Allcheck,this.tableData,this.CarList)
            if(this.Allcheck){
                this.shopShow = true
            }
        },
		// 门户类型切换
		PortalShow(item, index) {
			console.log(index)
			this.PortalType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			console.log(this.PortalType, '门户')
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 频道类型切换
		ChannelShow(item, index) {
			this.ChannelType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			console.log(this.ChannelType, '频道')
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 所属区域类型切换
		TerritoryShow(item, index) {
			this.TerritoryType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			console.log(this.TerritoryType, '所属区域')
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 平均阅读量
		RecordingShow(item, index) {
			this.RecordingType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			console.log(this.RecordingType, '收录效果')
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 价格区间切换
		MoneyShow(item, index) {
			this.MoneyType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			console.log(this.MoneyType, '价格区间')
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 特殊类目切换
		SpecialShow(item) {
			this.SpecialType = item
			// console.log(this.SpecialType);
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 获取列表数据
		ListFn() {
			let order = null
			let data = {}
			data.page = this.page
			data.limit = 20
			data.category_id = 2
			if (this.mediaName != '') {
				data.title = this.mediaName
			}
			if (this.SortType.title == '价格') {
				data.sort = "member_price"
				if (this.MoneyState == 1) {
					data.order = 'asc'
				} else {
					data.order = 'desc'
				}
			}
			if (this.PortalType.id != 0) {
				data.portal_id = this.PortalType.id
			}
			if (this.ChannelType.id != 0) {
				data.industry_id = this.ChannelType.id
			}
			if (this.MoneyType.id != 0) {
				data.price_id = this.MoneyType.id
			}
			if (this.RecordingType.id != 0) {
				data.views = this.RecordingType.id
			}
			if (this.isVAuthType == '未认证') {
				data.is_v_auth = 0
			} else if (this.isVAuthType == 'V认证') {
				data.is_v_auth = 1
			}
			if (this.SpecialType == '焦点图') {
				data.is_pub_image = 1
			} else if (this.SpecialType == '文字链') {
				data.is_pub_url = 1
			} else if (this.SpecialType == '周末可发') {
				data.is_pub_weekend = 1
			} else if (this.SpecialType == '是否可加视频') {
				data.is_pub_video = 1
			}
			if (this.TerritoryType.id != 0) {
				data.fans_num = this.TerritoryType.id
			}
			data.collect = this.collect
			if (this.officialType == '非官方媒介') {
				data.is_official = 0
			} else if (this.officialType == '官方媒介') {
				data.is_official = 1
			}
			data.collect = this.collect
			if (this.includeType.id != 0) {
				data.inlet_category = this.includeType.id
			}
			if (this.mediaName !== '') {
				data.title = this.mediaName
			}
			if (this.link != '' && this.link == '是') {
				data.is_pub_url = 1
			} else if (this.link !== '' && this.link == '否') {
				data.is_pub_url = 0
			}
			if (this.relation !== '' && this.relation == '是') {
				data.is_pub_contact = 1
			} else if (this.relation !== '' && this.relation == '否') {
				data.is_pub_contact = 0
			}
			console.log(data, 'data');
			this.fullscreenLoading = true
			this.curlGet('/api/medium/list', data).then((res) => {
				console.log(res)
				if (res.data.code) {
					this.tableData = res.data.data.list
					this.total_page = res.data.data.total_page
					this.count = res.data.data.count
					this.tableData.forEach((item, index) => {
						this.$set(this.tableData[index], 'check', false)
						if (this.CarList.length != 0) {
							this.CarList.forEach((el, i) => {
								if (this.tableData[index].id == this.CarList[i].id) {
									this.tableData[index].check = true
								}
							})
						}
					})
					this.shopDate(this.tableData, this.$store.state.shopObj, this.CarList)

				}
				this.fullscreenLoading = false
			})
		},
		excelPush() {
			this.json_data = []
			this.tableData.forEach((item, index) => {
				this.json_data.push({
					title: item.title,
					price: item.price,
					industry_classify_title: item.industry_classify_title,
					case_url: item.case_url,
					area_title: item.area_title,
					is_pub_url: item.is_pub_url ? '是' : '否',
					is_pub_contact: item.is_pub_contact ? '是' : '否',
					is_pub_image: item.is_pub_image ? '是' : '否',
					draft_speed: item.draft_speed == 0 ? '不限' : item.draft_speed == 1 ? '1小时' : item.draft_speed == 2 ? '2小时' : item.draft_speed == 3 ? '3小时' : item.draft_speed == 4 ? '4小时' : item.draft_speed == 5 ? '当天' : '次日',
					remark: item.remark,
				})
			})
		},
		// 重置选择
		AllMediaTab() {
			this.PortalType = {
				id: 0,
				title: '不限',
			}
			this.ChannelType = {
				id: 0,
				title: '不限',
			}
			this.TerritoryType = {
				id: 0,
				title: '不限',
			}
			this.RecordingType = {
				id: 0,
				title: '不限',
			}

			this.MoneyType = {
				id: 0,
				title: '不限',
			}
			this.SortType = {
				id: 0,
				title: '默认',
			}
			this.includeType = {
				id: 0,
				title: '不限'
			}
			this.mediaName = ''
			this.page = this.pageChangeOne()
			this.isVAuthType = '不限'
			this.officialType = '不限'
			this.link = ''
			this.relation = ''
			this.SpecialType = '不限'

			this.fullscreenLoading = true
			this.ListFn()
		},
		// 删除
		deleteFn(item) {
			this.CarList.forEach((el, index) => {
				if (el.id == item.id) {
					return this.CarList.splice(index, 1)
				}
			})
			this.tableData.forEach((el, index) => {
				if (el.id == item.id) {
					return this.tableData[index].check = false
				}
			})

		},
		// 立即投放
		putFn() {
			if (this.CarList.length == 0) {
				return this.$message({
					type: 'warning',
					message: '请选择套餐',
				})
			} else if (!this.$user_info) {
				this.$message({
					type: 'warning',
					message: '请登录',
				})
			} else {
				this.$store.commit('shopPush', this.CarList)
				if (this.$store.state.fillActive == false) {
					this.$router.push('/user/SetMenu/ManuscriptPreview')
				} else {
					this.$store.commit('childTab', '自媒体发布')
					this.$router.push({
						path: '/user/SetMenu/fill?id=2',
					})
				}
			}
		},
		// 收藏
		collectGet(item) {
			console.log(item);
			this.curlGet('/api/medium/collect', {
				mid: item.id,
				style: 1
			}).then(res => {
				console.log(res)
				if (res.data.code) {
					this.$message({
						message: '收藏成功',
						type: 'success'
					})
					this.ListFn()
				}
			})
		},
		// 取消收藏
		collectClose(item) {
			this.$confirm('是否取消收藏?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.curlGet('/api/medium/collect', {
					mid: item.id,
					style: 1
				}).then(res => {
					console.log(res)
					if (res.data.code) {
						this.$message({
							type: 'success',
							message: '取消收藏成功!'
						});
						this.ListFn()
					}
				})

			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});
			});

		},
		// 收录效果
		includeShow(item, index) {
			this.includeType = {
				title: item,
				id: index ? parseInt(index) : 0
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 是否为官方媒体
		isOfficialShow(item) {
			this.officialType = item
			this.page = this.pageChangeOne()
			this.ListFn()
		},
	},
}
</script>

<style lang=scss scoped>
@import '@/scss/shopCar.scss';

.box {
	position: relative;
	margin-bottom: 7.33rem;
	z-index: 999;

	.derive_but {
		width: 6.67rem;
		height: 2.5rem;
		border: 0;
		cursor: pointer;
		background: #108CDD;
		color: #fff;
		border-radius: 0.42rem;
		position: absolute;
		top: -45px;
		right: 0;
		z-index: 999;
	}

	// 数据
	.ct_box {
		width: 100%;
		background: #fff;
		margin: 0 auto;
		padding: 0 0;
		position: relative;
		border-radius: 0.88rem;
		box-shadow: 0.17rem 0.17rem 0.33rem #7a7a7a;

		.tab_ul {
			border-bottom: 0.1rem solid #cec8c8;
			padding: 0 1rem;
			box-sizing: border-box;
			align-items: center;

			.ul1 {
				padding: 0.5rem 0;
				align-items: center;

				li {
					padding: 1rem 1rem;
					font-size: 1.3rem;
					transition: 0.2s all;
					border-radius: 0.5rem;
					cursor: pointer;
					transition: 0.3s;

					&:hover {
						color: #168ad9;
						font-weight: 700;
					}
				}
			}

			.null_but {
				width: 6.67rem;
				height: 2.5rem;
				border-radius: 0.42rem;
				background: #108cdd;
				color: #fff;
				border: 0;
				box-shadow: 0.17rem 0.17rem 0.33rem #7a7a7a;
			}
		}

		.type2 {
			// padding: 0rem 1rem;
			box-sizing: border-box;
			border-bottom: 0.1rem solid #cec8c8;
			font-size: 1.22rem;

			p {
				width: 95px;
				color: #000;
				text-align: right;
				font-size: 1.22rem;
				padding-top: 1.7rem;
			}

			.ul2 {
				width: 90%;
				flex-wrap: wrap;
				// border-bottom: 0.1rem solid #CEC8C8;
				align-items: center;
				padding: 1rem 0;

				li {
					padding: 0.7rem 1rem;
					font-size: 1.22rem;
					transition: 0.2s all;
					border-radius: 0.5rem;
					// margin-right: 1.5rem;
					cursor: pointer;

					&:hover {
						color: #168ad9;
					}
				}
			}
		}

		// 排序
		.type3 {
			padding: 0rem 1rem;
			box-sizing: border-box;
			border-bottom: 0.1rem solid #cec8c8;
			font-size: 1.22rem;

			p {
				width: 95px;
				color: #000;
				text-align: right;
				font-size: 1.22rem;

				padding-top: 1.7rem;
			}

			.ul2 {
				width: 70%;
				flex-wrap: wrap;
				// border-bottom: 0.1rem solid #CEC8C8;
				align-items: center;
				padding: 1rem 0;

				li {
					padding: 0.7rem 2rem 0.7rem 0.7rem;
					font-size: 1.22rem;
					transition: 0.2s all;
					border-radius: 0.5rem;
					margin-right: 0.8rem;
					position: relative;
					cursor: pointer;

					&:nth-child(1) {
						margin-right: 0;
						padding: 0.7rem;
					}

					&:nth-child(2) {
						&::before {
							content: '';
							width: 1rem;
							height: 1rem;
							background: url('~@/assets/media/up.png') no-repeat;
							background-size: 100%;
							position: absolute;
							right: 5px;
							top: 10px;
						}

						&::after {
							content: '';
							width: 1rem;
							height: 1rem;
							background: url('~@/assets/media/down.png') no-repeat;
							background-size: 100%;
							position: absolute;
							right: 5px;
							top: 22px;
						}
					}

					&:nth-child(3) {
						&::before {
							content: '';
							width: 1rem;
							height: 1rem;
							background: url('~@/assets/media/up.png') no-repeat;
							background-size: 100%;
							position: absolute;
							right: 5px;
							top: 10px;
						}

						&::after {
							content: '';
							width: 1rem;
							height: 1rem;
							background: url('~@/assets/media/down.png') no-repeat;
							background-size: 100%;
							position: absolute;
							right: 5px;
							top: 22px;
						}
					}

					&:nth-child(4) {
						&::before {
							content: '';
							width: 1rem;
							height: 1rem;
							background: url('~@/assets/media/up.png') no-repeat;
							background-size: 100%;
							position: absolute;
							right: 5px;
							top: 10px;
						}

						&::after {
							content: '';
							width: 1rem;
							height: 1rem;
							background: url('~@/assets/media/down.png') no-repeat;
							background-size: 100%;
							position: absolute;
							right: 5px;
							top: 22px;
						}
					}
				}

				#activeTrue {
					padding: 0.7rem 2rem 0.7rem 0.7rem;
					font-size: 1.3rem;
					transition: 0.2s all;
					border-radius: 0.5rem;
					margin-right: 0.8rem;
					position: relative;
					cursor: pointer;

					&::before {
						content: '';
						width: 1rem;
						height: 1rem;
						background: url('~@/assets/media/up1.png') no-repeat;
						background-size: 100%;
						position: absolute;
						right: 5px;
						top: 10px;
					}
				}

				#activeFalse {
					padding: 0.7rem 2rem 0.7rem 0.7rem;
					font-size: 1.3rem;
					transition: 0.2s all;
					border-radius: 0.5rem;
					margin-right: 0.8rem;
					position: relative;
					cursor: pointer;

					&::after {
						content: '';
						width: 1rem;
						height: 1rem;
						background: url('~@/assets/media/down1.png') no-repeat;
						background-size: 100%;
						position: absolute;
						right: 5px;
						top: 22px;
					}
				}
			}
		}

		// 筛选
		.type4 {
			padding: 1rem 0rem;
			border-bottom: 0.1rem solid #cec8c8;
			align-items: center;

			.type_title_n {
				width: 90px;
				color: #000;
				text-align: right;
				font-size: 1.22rem;
				// padding-top: 1.7rem;
			}

			::v-deep .el-input {
				width: 15rem;
			}

			.link_box {
				align-items: center;
				padding: 0 1.5rem;

				p {
					color: #000;
					font-size: 1.22rem;
				}

				::v-deep .el-input--suffix {
					width: 10rem;
				}
			}

			.sf_but {
				cursor: pointer;
				border-radius: 0.2rem;
				padding: 1rem 2rem;
				border: 0;
				color: #fff;
				background: #108CDD;
				margin-left: 1rem;
			}

			.sf_but2 {
				cursor: pointer;
				border-radius: 0.2rem;
				padding: 1rem 2rem;
				border: 0;
				color: #fff;
				background: #108CDD;
				margin: 0 1rem;
			}
		}
	}

	.table_box {
		margin-top: 3rem;
		padding: 1rem 0;
		box-sizing: border-box;

		.table_bg_box {
			padding: 1.5rem 0 3rem 0;
			box-sizing: border-box;
			background: #fff;
			border-radius: 0.88rem;

			.title_box {
				padding: 0 2rem;
				box-sizing: border-box;
				justify-content: space-between;
				align-items: center;

				.search_box {
					width: 23.33rem;
					height: 3.33rem;
					border: 0.08rem solid #c5e6fc;
					border-radius: 0.42rem;
					box-shadow: 0.17rem 0.17rem 0.33rem#7A7A7A;
					align-items: center;
					padding-left: 1.2rem;
					box-sizing: border-box;
					justify-content: space-between;

					.search {
						display: block;
						width: 1.42rem;
						height: 1.42rem;

						background: url('~@/assets/user/setMenu/search.png') no-repeat;
						background-size: 100%;
					}

					.search_text {
						outline: none;
						border: 0;
						color: #6f90a6;
						font-size: 1.2rem;
					}

					.search_but {
						border: 0;
						color: #fff;
						font-size: 1.17rem;
						border-radius: 0 0.42rem 0.42rem 0;
						background: #b5e1ff;
						width: 5.83rem;
						height: 3.33rem;
						cursor: pointer;
					}
				}


			}

			.table_2 {
				width: 100%;
				margin: 20px auto;
				// border-spacing: 0;
				border-collapse: collapse;

				// 标题
				thead {
					border-bottom: 1px solid #e6e9f4;
					margin-bottom: 5rem;

					td {
						color: #0d8adc;
						font-weight: bold;
						font-size: 1.3rem;
						text-align: center;
						padding-bottom: 1.5rem;
						box-sizing: border-box;
					}
				}

				tbody {
					tr {
						border-bottom: 1px solid #e6e9f4;
						cursor: pointer;
						transition: 0.3s;

						&:last-child {
							border: 0
						}

						&:hover {
							background: #e0f3ff;
						}
					}

					td {
						color: #000;
						font-size: 1.22rem;
						text-align: center;
						padding: 1.2rem;
						box-sizing: border-box;
						position: relative;

						input {
							border: 1px solid #0d8adc;
						}

						.place_but {
							padding: 0.8rem 1rem 0.8rem 3.5rem;
							color: #fff;
							font-size: 1.4rem;
							background: #108cdd;
							border: 0;
							border-radius: 5px;
							position: relative;
							cursor: pointer;

							&::before {
								content: '';
								width: 1.8rem;
								height: 1.8rem;
								background: url('~@/assets/media/shopCar2.png') no-repeat;
								background-size: 100%;
								position: absolute;
								left: 1rem;
								top: 0.8rem;
							}
						}

						.collect_box {
							justify-content: space-between;

							p {
								width: 3rem;
								color: #FF8400;
								font-size: 1.22rem;
								cursor: pointer;
							}

							.collect {
								color: #000;
								cursor: pointer;
							}

							.collect2 {
								color: #108CDD;
								cursor: pointer;
							}

						}
					}
				}
			}
		}
	}
}

.SetMenu_tab {
	li {
		width: 5.67rem;
		height: 2.5rem;
		border-radius: 0.42rem;
		background: #ffffff;
		box-shadow: 0.17rem 0.17rem 0.33rem #7a7a7a;
		text-align: center;
		line-height: 2.5rem;
		color: #108cdd;
		cursor: pointer;

		&:nth-child(1) {
			margin-right: 1.5rem;
		}
	}

	.SetMenu_Show {
		background: #108cdd;
		color: #ffffff;
	}
}

.weight_back {
	margin: 0 auto;
	background: url('~@/assets/media/分组 1.png') no-repeat;
	background-size: 100%;
	width: 6rem;
	height: 2rem;
	color: #fff;
	font-size: 1.5rem;
	text-indent: 3.2rem;
}

// 被选中
.active {
	color: #168ad9 !important;
}


@keyframes shop_Show {
	to {
		transform: translateX(0%);
	}
}



@keyframes shop_none {
	to {
		transform: translateX(100%);
	}
}
</style>